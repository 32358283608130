import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { postscribePromise } from "../services/postscribe";

import { fetchVekaConfiguration } from "../store/actions";
import {
  hasLoadedBurgerprofiel,
  selectBurgerprofielFooter,
} from "../store/selectors";

const reduxProps = {
  hasLoadedBurgerprofiel: PropTypes.bool,
  burgerprofielFooterEmbedCode: PropTypes.string,
  fetchVekaConfiguration: PropTypes.func,
};

export class vekaFooter extends React.Component {
  static propTypes = reduxProps;

  constructor(props) {
    super(props);
    props.fetchVekaConfiguration();

    this.state = {
      hasInitializedFooterScript: false,
      footerSelector: "#footer-script",
    };
  }

  componentDidUpdate() {
    if (
      this.state.hasInitializedFooterScript ||
      !this.props.hasLoadedBurgerprofiel
    ) {
      return;
    }

    if (this.props.burgerprofielFooterEmbedCode) {
      const burgerprofielPolyfillSrc =
        "https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js";

      postscribePromise(this.state.footerSelector, burgerprofielPolyfillSrc)
        .then(() =>
          postscribePromise(
            this.state.footerSelector,
            this.props.burgerprofielFooterEmbedCode
          )
        )
        .then(() => this.setState({ hasInitializedFooterScript: true }))
        .catch(() => this.loadDefaultFooter());

      return;
    }

    this.loadDefaultFooter();
  }

  loadDefaultFooter() {
    const voFooterEmbedCode =
      "//widgets.vlaanderen.be/widget/live/357d5f5724eb4be8bb1fe04056ff866b";
    postscribePromise(this.state.footerSelector, voFooterEmbedCode).then(() =>
      this.setState({ hasInitializedFooterScript: true })
    );
  }

  render() {
    return <div id="footer-script"></div>;
  }
}

const mapStateToProps = (state) => ({
  hasLoadedBurgerprofiel: hasLoadedBurgerprofiel(state),
  burgerprofielFooterEmbedCode: selectBurgerprofielFooter(state),
});

const mapDispatchToProps = {
  fetchVekaConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(vekaFooter);
