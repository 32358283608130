import React from 'react';
import { get } from 'lodash';
import moment from 'moment';

import contextType from '@skryv/core-react/src/services/contextTypes';
import { externalProps, internalProps, defaultProps } from '@skryv/core-react/src/components/dossier/DossierPage/DossierPage';
import { notificationTypes } from '@skryv/core-react/src/components/base/Notification/Notification';
import { DossierPage as CoreDossierPage } from '@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage';

import contactInformation from '../../constants/contactInformation';
import documentDefinitionKeys from '../../constants/documentDefinitionKeys';
import milestoneDefinitionKeys from '../../constants/milestoneDefinitionKeys';
import taskDefinitionKeys from '../../constants/taskDefinitionKeys';


export default class vekaRentesubsidieDossierPage extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    componentDidUpdate(prevProps) {
      if (this.props.dossierDetails !== prevProps.dossierDetails) {
        // the props have just been updated; so either a parent updated the props or new values came in through redux
        this.setState({
          dossierInformation: this.getDossierInformation(),
          downloads: this.getDownloads(),
          notifications: this.getNotifications()
        });
      }
    }

    getDossierInformation() {
      const dossierInformation = [];
      if(this.props.dossier) {
        dossierInformation.push({
          value: get(this.props.dossier,'label'),
          label: this.context.gettext('Dossier label')
        });
        dossierInformation.push({
          value: moment(get(this.props.dossier,'updatedAt')).format('DD-MM-YYYY'),
          label: this.context.gettext('Last update')
        });
        dossierInformation.push({
          value: this.props.getFieldFromDocument(documentDefinitionKeys.RENTESUBSIDIE_AANVRAAG, 'credit_number'),
          label: this.context.gettext('Kredietnummer')
        });
        dossierInformation.push({
          value: '€ '.concat(this.props.getFieldFromDocument(documentDefinitionKeys.RENTESUBSIDIE_AANVRAAG, 'credit_capital')),
          label: this.context.gettext('Kredietkapitaal')
        });
        dossierInformation.push({
          value: this.props.getFieldFromDocument(documentDefinitionKeys.RENTESUBSIDIE_AANVRAAG, 'epc_attestnumber'),
          label: this.context.gettext('EPC-attestnummer')
        });
      }
      return dossierInformation;
    }

    getDownloads() {
      if (!this.props.dossierDetails) return [];
      const downloads = [];
      if ((this.props.getMilestone(milestoneDefinitionKeys.DOSSIER_GECORRIGEERD) || this.props.getMilestone(milestoneDefinitionKeys.DOSSIER_GECONFIRMEERD)) && (!this.props.getTask(taskDefinitionKeys.BEVESTIG_AANVRAAG, true) && !this.props.getTask(taskDefinitionKeys.WIJZIG_GEGEVENS_AANVRAAG, true))) {
        downloads.push(this.props.mapDocumentToPdfDownloadInfo(this.props.getDocument(documentDefinitionKeys.RENTESUBSIDIE_AANVRAAG), 'Rentesubsidie aanvraagformulier ', 'rentesubsidie_aanvraag'));
      }
      return downloads;
    }

    getNotifications() {
      if (!this.props.dossierDetails) return [];
      let notifications = [];
      if(this.props.getMilestone(milestoneDefinitionKeys.EPC_CONTROLE_NOK) !== undefined && this.props.getTask(taskDefinitionKeys.WIJZIG_GEGEVENS_AANVRAAG, true) !== undefined){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Ongeldige gegevens in aanvraag'),
          message: this.context.gettext('De controle van uw aanvraag voor het renteloos renovatiekrediet heeft fouten gevonden. Gelieve uw aanvraag aan te passen.'),
          type: notificationTypes.WARNING
        }];
      }
      if(this.props.getMilestone(milestoneDefinitionKeys.DOSSIER_GECORRIGEERD)){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Aanvraag rentesubsidie gecorrigeerd'),
          message: this.context.gettext('VEKA heeft de correctie van uw aanvraag ontvangen. Uw dossier wordt verwerkt.'),
          type: notificationTypes.SUCCESS
        }];
      }
      if(this.props.getMilestone(milestoneDefinitionKeys.EPC_CONTROLE_OK) !== undefined && this.props.getTask(taskDefinitionKeys.BEVESTIG_AANVRAAG, true) !== undefined){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Aanvraag rentesubsidie gevalideerd'),
          message: this.context.gettext('Uw aanvraag voor het renteloos renovatiekrediet werd gevalideerd. Gelieve uw aanvraag na te lezen en te bevestigen.'),
          type: notificationTypes.SUCCESS
        }];
      }
      if(this.props.getMilestone(milestoneDefinitionKeys.DOSSIER_GECONFIRMEERD)){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Aanvraag rentesubsidie geconfirmeerd'),
          message: this.context.gettext('VEKA heeft uw bevestiging van uw aanvraag ontvangen. Zodra alle kredietnemers hebben bevestigd zal uw dossier finaal goedgekeurd worden.'),
          type: notificationTypes.SUCCESS
        }];
      }
      if(this.props.getMilestone(milestoneDefinitionKeys.DOSSIER_GOEDGEKEURD)){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Aanvraag rentesubsidie goedgekeurd'),
          message: this.context.gettext('Uw aanvraag voor het renteloos renovatiekrediet werd goedgekeurd.'),
          type: notificationTypes.SUCCESS
        }];
      }
      if(this.props.getMilestone(milestoneDefinitionKeys.DOSSIER_AFGEKEURD)){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Aanvraag rentesubsidie afgekeurd'),
          message: this.context.gettext('Uw aanvraag voor het renteloos renovatiekrediet werd afgekeurd.'),
          type: notificationTypes.ERROR
        }];
      }
      if(this.props.getMilestone(milestoneDefinitionKeys.DOSSIER_HERACTIVEERD)){
        notifications = [{
          id: this.props.dossier.id,
          title: this.context.gettext('Dossier rentesubsidie heractiveerd'),
          message: this.context.gettext('Uw dossier voor het renteloos renovatiekrediet werd heractiveerd.'),
          type: notificationTypes.ERROR
        }];
      }
      return notifications;
    }

    render() {
      const propsToPass = {
        ...this.props,
        contactInformation,
        dossierInformation: this.getDossierInformation(),
        downloads: this.getDownloads(),
        notifications: this.getNotifications(),
        shouldShowContactInformation: true,
        shouldShowDossierInformation: true,
        shouldShowDownloads: true,
        shouldShowNotifications: true
      };
      return <CoreDossierPage { ...propsToPass } />;
    }
}
