import { get, isEmpty } from 'lodash';

export function selectProfileTypeFromVekaProfile(state) {
  return get(state, ['profile', 'type']);
}

export function selectProfileIsBankFromVekaProfile(state) {
  return get(state, ['profile', 'bank']);
}

export function selectBurgerprofielHeader(state) {
  return get(state,['vekaConfiguration','configuration','burgerprofiel','headerEmbedCode']);
}

export function selectBurgerprofielFooter(state) {
  return get(state,['vekaConfiguration','configuration','burgerprofiel','footerEmbedCode']);
}

export function hasLoadedBurgerprofiel(state) {
  return !get(state,['vekaConfiguration','loading']) && !isEmpty(get(state,['vekaConfiguration','configuration']));
}