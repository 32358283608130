import React from 'react';
import PropTypes from 'prop-types';
import './Loader.scss';

Loader.propTypes = {
  label: PropTypes.string.isRequired,
};

export default function Loader({ label }) {
  return (
    <div className="vl-col--1-1">
      <div className="vl-region">
        <div className="vl-u-align-center">
          <div className="vl-loader" role="alert" aria-busy="true"></div>
          <p>{ label }</p>

        </div>
      </div>
    </div>
  );
}

