import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CoreHeader from "@skryv/core-react-vo/src/components/layout/Header/Header";

import { loginUser } from "@skryv/core-react/src/core/store/actions/authentication";
import { fetchConfiguration } from "@skryv/core-react/src/core/store/actions/configuration";
import { isAuthenticated } from "@skryv/core-react/src/core/store/selectors/users";

import { postscribePromise } from "../services/postscribe";

import { fetchVekaConfiguration } from "../store/actions";
import {
  hasLoadedBurgerprofiel,
  selectBurgerprofielHeader,
} from "../store/selectors";

const reduxProps = {
  isAuthenticated: PropTypes.bool,
  hasLoadedBurgerprofiel: PropTypes.bool,
  burgerprofielHeaderEmbedCode: PropTypes.string,
  loginUser: PropTypes.func,
  fetchConfiguration: PropTypes.func,
  fetchVekaConfiguration: PropTypes.func,
};

export class vekaHeader extends React.Component {
  static propTypes = reduxProps;

  constructor(props) {
    super(props);

    props.loginUser();
    props.fetchConfiguration();
    props.fetchVekaConfiguration();

    this.state = {
      title: "VEKA - ",
      subtitle: "Subsidies ePortaal",
      hasInitializedHeaderScript: false,
      headerSelector: "#header-script",
      shouldShowProfile: false,
    };
  }

  componentDidUpdate() {
    if (
      this.state.hasInitializedHeaderScript ||
      !this.props.hasLoadedBurgerprofiel
    ) {
      return;
    }

    if (
      this.props.isAuthenticated &&
      this.props.burgerprofielHeaderEmbedCode
    ) {
      this.loadMijnBurgerprofielHeader()
        .then(() => this.setState({ hasInitializedHeaderScript: true }))
        .catch(() => this.loadDefaultHeader());
      return;
    }

    this.loadDefaultHeader();
  }

  loadMijnBurgerprofielHeader() {
    const burgerprofielPolyfillSrc =
      "https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-polyfill/dist/index.js";
    const burgerprofielClientSrc =
      "https://prod.widgets.burgerprofiel.vlaanderen.be/api/v1/node_modules/@govflanders/vl-widget-client/dist/index.js";

    return Promise.all([
      postscribePromise(this.state.headerSelector, burgerprofielPolyfillSrc),
      postscribePromise(this.state.headerSelector, burgerprofielClientSrc),
    ])
      .then(() =>
        postscribePromise(
          this.state.headerSelector,
          this.props.burgerprofielHeaderEmbedCode
        )
      )
      .then(() => {
        window.vl.widget.client.capture((widget) => {
          widget.getExtension("citizen_profile.session").then((session) => {
            session.configure({
              active: this.props.isAuthenticated,
              endpoints: {
                loginUrl: "/api/login/frontoffice",
                logoutUrl: "/api/logout",
              },
            });
          });
        });
      });
  }

  loadDefaultHeader() {
    const voHeaderEmbedCode =
      "https://widgets.vlaanderen.be/widget/live/e4e04cb859364804968c2d85949397fd";
    postscribePromise(this.state.headerSelector, voHeaderEmbedCode).then(() =>
      this.setState({
        hasInitializedHeaderScript: true,
        shouldShowProfile: true,
      })
    );
  }

  render() {
    return (
      <div>
        <div id="header-script"></div>
        <CoreHeader {...this.props} {...this.state}></CoreHeader>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  hasLoadedBurgerprofiel: hasLoadedBurgerprofiel(state),
  burgerprofielHeaderEmbedCode: selectBurgerprofielHeader(state),
});

const mapDispatchToProps = {
  loginUser,
  fetchConfiguration,
  fetchVekaConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(vekaHeader);
