export function fetchVekaProfile() {
  return {
    type: 'FETCH_VEKA_PROFILE',
    api: {
      method: 'GET',
      url: 'api/veka/profile',
      cache: true
    }
  };
}

export const FETCH_VEKA_APPLICATION_CONFIGURATION = 'FETCH_VEKA_CONFIGURATION';
export const FETCH_VEKA_APPLICATION_CONFIGURATION_RESPONSE = `${FETCH_VEKA_APPLICATION_CONFIGURATION}/RESPONSE`;
export const FETCH_VEKA_APPLICATION_CONFIGURATION_REQUEST = `${FETCH_VEKA_APPLICATION_CONFIGURATION}/REQUEST`;
export const FETCH_VEKA_APPLICATION_CONFIGURATION_ERROR = `${FETCH_VEKA_APPLICATION_CONFIGURATION}/ERROR`;

export function fetchVekaConfiguration() {
  return {
    type: FETCH_VEKA_APPLICATION_CONFIGURATION,
    api: {
      method: 'GET',
      url: 'api/veka/configuration',
      cache: true
    }
  };
}