import immutable from 'seamless-immutable';
import get from 'lodash/get';
import { FETCH_VEKA_APPLICATION_CONFIGURATION_ERROR, FETCH_VEKA_APPLICATION_CONFIGURATION_REQUEST, FETCH_VEKA_APPLICATION_CONFIGURATION_RESPONSE } from './actions';

export const profile = (state = immutable({}), action) => {
  if (action.type === 'FETCH_VEKA_PROFILE/RESPONSE') {
    return immutable(action.response.data);
  }
  return state;
};

export const currentMilestone = (state = immutable({}), action) => {
  if (action.type === 'FETCH_DOSSIER_CURRENT_MILESTONE/RESPONSE') {
    return state.merge({ [action.dosId]: { key: get(action.response.data, ['key']), label: get(action.response.data, ['status']) } });
  }
  return state;
};

export const vekaConfiguration = (state = immutable({ loading: false }), action) => {
  if (action.type === FETCH_VEKA_APPLICATION_CONFIGURATION_RESPONSE) {
    return immutable({ loading: false, configuration: action.response.data });
  }
  else if (action.type === FETCH_VEKA_APPLICATION_CONFIGURATION_REQUEST) {
    return immutable({ loading: true });
  }
  else if (action.type === FETCH_VEKA_APPLICATION_CONFIGURATION_ERROR) {
    return immutable({ loading: false });
  }
  return state;
};